// Fonts
@font-face {
	font-family: 'Lato';
	src: url('assets/fonts/lato/Lato-Light.eot');
	src: url('assets/fonts/lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/lato/Lato-Light.woff') format('woff'),
		url('assets/fonts/lato/Lato-Light.ttf') format('truetype'),
		url('assets/fonts/lato/Lato-Light.svg#Lato-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('assets/fonts/lato/Lato-Regular.eot');
	src: url('assets/fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/lato/Lato-Regular.woff') format('woff'),
		url('assets/fonts/lato/Lato-Regular.ttf') format('truetype'),
		url('assets/fonts/lato/Lato-Regular.svg#Lato-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('assets/fonts/lato/Lato-LightItalic.eot');
	src: url('assets/fonts/lato/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/lato/Lato-LightItalic.woff') format('woff'),
		url('assets/fonts/lato/Lato-LightItalic.ttf') format('truetype'),
		url('assets/fonts/lato/Lato-LightItalic.svg#Lato-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('assets/fonts/lato/Lato-Bold.eot');
	src: url('assets/fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/lato/Lato-Bold.woff') format('woff'),
		url('assets/fonts/lato/Lato-Bold.ttf') format('truetype'),
		url('assets/fonts/lato/Lato-Bold.svg#Lato-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('assets/fonts/lato/Lato-Italic.eot');
	src: url('assets/fonts/lato/Lato-Italic.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/lato/Lato-Italic.woff') format('woff'),
		url('assets/fonts/lato/Lato-Italic.ttf') format('truetype'),
		url('assets/fonts/lato/Lato-Italic.svg#Lato-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
}

// Styles
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.chatbot-container * {
    font-family: 'Lato';
    box-sizing: border-box;

    :focus {
        outline: none;
    }
    
    audio,
    canvas,
    video {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        max-width: 100%;
    }
}