$blue: #21b4ec;
$brown: #B19834;
$gray-1: #CACFD2;
$gray-2: #f2f2f2;
$gray-3: #f5f6f7;

.chatbot-button-wrap {
    position: fixed;
    right: 25px;
    bottom: 20px;
    font-family: 'Lato';
}

.chatbot-button-avatar {
    background: url("assets/secuencia-2.png");
    display: block;
    width: 120px;
    height: 130px;
    animation: moveAvatar 2s steps(9) infinite alternate;
}

.chatbot-button-msg {
    position: absolute;
    background: $brown;
    width: 250px;
    min-height: 46px;
    right: 110px;
    bottom: 100px;
    padding: 10px 12px;
    border-radius: 1rem;
    border-bottom-right-radius: 0;
    box-shadow: 0 0 3px 1px rgba(black, .2);

    :before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: -22px;
        bottom: 0;
        border-bottom: 18px solid $brown;
        border-right: 22px solid transparent;
        box-sizing: border-box;
    }
	
    p, h4 {
        color: white;
        text-align: center;
    }

    h4 {
        margin: 0 0 5px;
    }

    p {
        margin: 5px 0;
        font-size: 1rem;
        line-height: 1rem;
        
        :last-child {
            margin-bottom: 0;
        }
    }
}

// Keyframes

@keyframes moveAvatar {
    from {
        background-position-x: 0;
    }
    to {
        background-position: -1072px;
    }
}

@keyframes faceAvatar{
    from {
        background-position-x: 0;
    }

    to {
        background-position: -356px;
    }
}